<template>
  <div class="step-content">
    <div class="d-block">
      <div class="title">{{ $t('questions.step_4.title') }}</div>
      <div class="check-all">{{ $t('questions.step_4.description') }}</div>
      <SelectBox @update-val="updateVal"
                 ref="selectBox"
                 :check-data="$t('questions.step_4.checkbox')"/>
    </div>
    <div class="d-flex justify-content-center">
      <RestartBtn/>
      <router-link class="btn" to="5" :class="{disabled: !selected.length}">{{
          $t('questions.step_4.btnText')
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";
import {updateVal} from "../mixins/update-val";

export default {
  name: 'Step_4',
  mixins: [updateVal],
  components: {
    SelectBox
  }
}
</script>
